import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  paid: [],
  nonPaid: [],
  newUser: [],
  totalUser: [],
  state: "idle",
  error: null,
  success: null,
};

export const adminDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAID_USER:
      return {
        ...state,
        paid: action.payload,
      };
    case actionTypes.GET_NON_PAID_USER:
      return {
        ...state,
        nonPaid: action.payload,
      };
    case actionTypes.GET_NEW_USER:
      return {
        ...state,
        newUser: action.payload,
      };
    case actionTypes.GET_TOTAL_USER:
      return {
        ...state,
        totalUser: action.payload,
      };
    default:
      return state;
  }
};

export default adminDashboardReducer;
