import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  service: [],
  state: "idle",
  error: null,
  success: null,
};

export const adminServiceReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case actionTypes.GET_ADMIN_SERVICE:
      return {
        ...state,
        service: action.payload,
      };
    case actionTypes.UPDATE_ADMIN_SERVICE:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
  

    default:
      return state;
  }
};

export default adminServiceReducer;
