import * as actionTypes from '../../../constants/actionTypes';

const initialState = {
    login: null, 
    isAuthenticated: false,
    state: 'idle', 
    error: null
};


const storedProfile = localStorage.getItem('profile');
const initialProfile = storedProfile ? JSON.parse(storedProfile) : null;

const adminLoginReducer = (state = { ...initialState, login: initialProfile }, action) => {
    switch (action.type) {
       
        case actionTypes.LOGIN:
           
            localStorage.setItem('profile', JSON.stringify({ ...action?.payload }));
            return {
                ...state,
                login: action.payload,
                isAuthenticated: true,
            };
            case actionTypes.LOGOUT:
                localStorage.removeItem('profile');
                return {
                    ...state,
                    login: null,
                    isAuthenticated: false,
                };
        default:
            return state;
    }
};

export default adminLoginReducer;
