import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import './App.css';
import LayoutLoader from './components/loader/LayoutLoader';

// Lazy load components
const CreationForm = lazy(() => import('./components/user/services/cardForm/CreationForm'));
const Register = lazy(() => import("./components/user/register/Register"));
const MyLayout = lazy(() => import("./components/user/layout/MyLayout"));
const ResendOtp = lazy(() => import('./components/user/login/ResendOtp'));
const Login = lazy(() => import("./components/user/login/Login"));
const Home = lazy(() => import("./components/home/Home"));
const AdminLogin = lazy(() => import("./components/admin/login/Login"));
const AdminLayout = lazy(() => import('./components/admin/layout/AdminLayout'));
const UserService = lazy(() => import('./components/admin/userService/UserService'));
const AnalysisPage = lazy(() => import('./components/admin/analysisPage/AnalysisPage'));
const ThankYou = lazy(() => import('./components/user/thankyou/ThankYou'));
const AboutPage = lazy(() => import('./components/aboutPage/AboutPage'));
const OurTeam = lazy(() => import("./components/ourTeam/OurTeam"));
const MutualFund = lazy(() => import('./components/productPage/MutualFund'));
const LifeInsurance = lazy(() => import('./components/productPage/insurance/LifeInsurance'));
const HealthInsurance = lazy(() => import('./components/productPage/insurance/HealthInsurance'));
const DematAccount = lazy(() => import('./components/dematAccount/DematAccount'));
const GoldBonds = lazy(() => import("./components/goldBonds/GoldBonds"));
const FDAlternatives = lazy(() => import('./components/fdAlternatives/FDAlternatives'));
const Club = lazy(() => import('./components/club/Club'));
const PersonalFinance = lazy(() => import('./components/finance/PersonalFinance'));
const SIPCalculator = lazy(() => import('./components/calculator/SIPCalculator'));
const LumpSumCalculator = lazy(() => import('./components/calculator/LumpSumCalculator'));
const SWPCalculator = lazy(() => import('./components/calculator/SwapCalculator'));
const FullIPOList =lazy(()=>import("./components/IPOComponent/FullIPOList"));

function App() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <BrowserRouter>
       <Suspense fallback={<LayoutLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign_up" element={<Register />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/mutual-funds" element={<MutualFund />} />
          <Route path="/life-insurance" element={<LifeInsurance />} />
          <Route path="/health-insurance" element={<HealthInsurance />} />
          <Route path="/demat-account" element={<DematAccount />} />
          <Route path="/fd-alternatives" element={<FDAlternatives />} />
          <Route path="/sip-calculator" element={<SIPCalculator />} />
          <Route path="/lumpsum-calculator" element={<LumpSumCalculator />} />
          <Route path="/swap-calculator" element={<SWPCalculator />} />
          <Route path="/9-club" element={<Club />} />
          <Route path="/gold-bonds" element={<GoldBonds />} />
          <Route path="/personal-finance" element={<PersonalFinance />} />
          <Route path="/sign_in" element={<Login />} />
          <Route path="/resend_otp" element={<ResendOtp />} />
          <Route path="/user/*" element={<MyLayout />} />
          <Route path="/user/creation-form" element={<CreationForm />} />
          <Route path="/user/thank-you" element={<ThankYou />} />
          <Route path="/admin/sign_in" element={<AdminLogin />} />
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="/admin/user-services/:id" element={<UserService />} />
          <Route path="/admin/:serviceId/:userId" element={<AnalysisPage />} />
          <Route path="/full-list/:type" element={<FullIPOList />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
