import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  users: [],
  result:[],
  userById:[],
  state: "idle",
  error: null,
  success: null,
};

export const adminUsersReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case actionTypes.GET_ADMIN_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.GET_ADMIN_USER_RESULT:
      return {
        ...state,
        result: action.payload,
      };
  
      case actionTypes.GET_ADMIN_USER_BY_ID:
        return {
          ...state,
          userById: action.payload,
        };

    default:
      return state;
  }
};

export default adminUsersReducer;
