import { combineReducers } from "redux";
import addUser from "./users/users";
import stock from "./stock/stock";
import creation from "./creation/creation";
import analysis from "./analysis/analysis";
import service from "./service/service";
import payment from "./payment/payment";
import comment from "./comment/comment";
import adminLogin from "./admin/adminLogin/adminLogin";
import adminService from "./admin/service/service";
import adminUsers from "./admin/users/users";
import adminUserStock from "./admin/stock/stock";
import adminUserAnalysis from "./admin/analysis/analysis";
import adminDashboard from "./admin/dashboard/dashboard";
import notification from "./admin/notification/notification";
import adminQuestion from "./admin/question/question";
import adminPayment from "./admin/adminPayment/adminPayment";

export const reducers = combineReducers({
  addUser,
  stock,
  creation,
  analysis,
  service,
  payment,
  comment,
  adminLogin,
  adminService,
  adminUsers,
  adminUserStock,
  adminUserAnalysis,
  adminDashboard,
  notification,
  adminQuestion,
  adminPayment
  

    
});
