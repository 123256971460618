import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  stock: [],
  state: "idle",
  error: null,
  success: null,
};

export const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_STOCK:
      return {
        ...state,
        stock: action.payload.stock,
      };
    case actionTypes.GET_STOCK:
      return {
        ...state,
        stock: action.payload,
      };
    case actionTypes.UPDATE_STOCK:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case actionTypes.DELETE_STOCK:
      const stockIdToDelete = action.payload;

      return {
        ...state,
        stock: state.stock.data.filter((stock) => stock.id !== stockIdToDelete),
      };

    default:
      return state;
  }
};

export default stockReducer;
