import React from "react";

const LayoutLoader = () => {
  return (
    <div className="banner skeleton-loader">
      <div className="banner__bg skeleton-bg"></div>
      <div className="container">
        <div className="banner__wrapper">
          <div className="row gy-5 gx-4">
            <div className="col-lg-6 col-md-7">
              <div className="banner__content h-5/6">
                <div className="banner__content-coin skeleton-coin"></div>
                <div className="banner__content-heading skeleton-heading"></div>
                <div className="banner__content-heading skeleton-heading"></div>
                <div className="banner__content-heading skeleton-heading"></div>
                <div className="banner__content-moto skeleton-moto"></div>
                <div className="banner__btn-group flex">
                  <div className="skeleton-btn"></div>
                  <div className="skeleton-btn"></div>
                </div>
                <div className="banner__content-social">
                  <div className="skeleton-social"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5">
              <div className="banner__thumb skeleton-thumb"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="banner__shape skeleton-shape"></div> */}
    </div>
  );
};

export default LayoutLoader;
