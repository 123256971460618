import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  stock: [],
  state: "idle",
  error: null,
  success: null,
};

export const adminUserStockReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case actionTypes.GET_ADMIN_USER_STOCK:
      return {
        ...state,
        stock: action.payload,
      };
    default:
      return state;
  }
};

export default adminUserStockReducer;
