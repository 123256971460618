import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  analysis: [],
  state: "idle",
  error: null,
  success: null,
};

export const analysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ANALYSIS:
      return {
        ...state,
        analysis: action.payload.analysis,
      };
    case actionTypes.GET_ANALYSIS:
      return {
        ...state,
        analysis: action.payload,
      };
    case actionTypes.UPDATE_ANALYSIS:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case actionTypes.DELETE_ANALYSIS:
      const analysisIdToDelete = action.payload;

      return {
        ...state,
        analysis: state.analysis.data.filter((analysis) => analysis.id !== analysisIdToDelete),
      };

    default:
      return state;
  }
};

export default analysisReducer;
