import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  question: [],
  state: "idle",
  error: null,
  success: null,
};

export const adminQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADMIN_QUESTION:
      return {
        ...state,
        question: action.payload,
      };

    default:
      return state;
  }
};

export default adminQuestionReducer;
