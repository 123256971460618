import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  analysis: [],
  report:[],
  state: "idle",
  error: null,
  success: null,
};

export const adminUserAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case actionTypes.GET_ADMIN_USER_ANALYSIS:
      return {
        ...state,
        analysis: action.payload,
      };
      case actionTypes.GET_ANALYSIS_REPORT:
        return {
          ...state,
          report: action.payload,
        };
        case actionTypes.SUBMIT_REPORT:
          return {
            ...state,
            report: action.payload.report,
          };
          case actionTypes.DELETE_REPORT:
      const reportIdToDelete = action.payload;

      return {
        ...state,
        report: state.report.data.filter((report) => report.id !== reportIdToDelete),
      };
    default:
      return state;
  }
};

export default adminUserAnalysisReducer;
