import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  notification: [],
  state: "idle",
  error: null,
  success: null,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
      case actionTypes.UPDATE_NOTIFICATION:
        return {
          ...state,
          success: action.payload,
          error: null,
        };

    default:
      return state;
  }
};

export default notificationReducer;
