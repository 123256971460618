import * as actionTypes from "../../../constants/actionTypes";

const initialState = {
  payment: [],
  failed:[],
  state: "idle",
  error: null,
  success: null,
};

export const adminPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case actionTypes.GET_ADMIN_PAYMENT:
      return {
        ...state,
        payment: action.payload,
      };
      case actionTypes.GET_FAILED_PAYMENT:
        return {
          ...state,
          failed: action.payload,
        };
     
    default:
      return state;
  }
};

export default adminPaymentReducer;
