export const ADD_USER='ADD_USER';
export const VERIFY_OTP='VERIFY_OTP';
export const LOGIN_USER='LOGIN_USER';
export const GET_USER='GET_USER';
export const UPDATE_USER='UPDATE_USER';
export const LOGOUT_USER='LOGOUT_USER';

export const ADD_STOCK='ADD_STOCK';
export const GET_STOCK='GET_STOCK';
export const UPDATE_STOCK='UPDATE_STOCK';
export const DELETE_STOCK='DELETE_STOCK';

export const GET_QUESTION='GET_QUESTION';
export const ATTEMPT_QUESTION='ATTEMPT_QUESTION';
export const GET_RESULT='GET_RESULT';

export const ADD_ANALYSIS='ADD_ANALYSIS';
export const GET_ANALYSIS='GET_ANALYSIS';
export const UPDATE_ANALYSIS='UPDATE_ANALYSIS';
export const DELETE_ANALYSIS='DELETE_ANALYSIS';

export const GET_SERVICES='GET_SERVICES';

export const ADD_PAYMENT='ADD_PAYMENT';
export const PROFILE_IMAGE='PROFILE_IMAGE';

export const ADD_COMMENT='ADD_COMMENT';

// admin
export const LOGIN='LOGIN';
export const LOGOUT='LOGOUT';
export const GET_ADMIN_SERVICE='GET_ADMIN_SERVICE';
export const UPDATE_ADMIN_SERVICE='UPDATE_ADMIN_SERVICE';
export const GET_ADMIN_USERS='GET_ADMIN_USERS';
export const GET_ADMIN_USER_STOCK='GET_ADMIN_USER_STOCK';
export const GET_ADMIN_USER_ANALYSIS='GET_ADMIN_USER_ANALYSIS';
export const GET_ANALYSIS_REPORT="GET_ANALYSIS_REPORT";

export const GET_NON_PAID_USER='GET_NON_PAID_USER';
export const GET_NEW_USER='GET_NEW_USER';
export const GET_PAID_USER='GET_PAID_USER';
export const GET_TOTAL_USER='GET_TOTAL_USER';
export const GET_NOTIFICATION='GET_NOTIFICATION';
export const UPDATE_NOTIFICATION='UPDATE_NOTIFICATION';
export const GET_ADMIN_QUESTION="GET_ADMIN_QUESTION";
export const GET_ADMIN_USER_RESULT='GET_ADMIN_USER_RESULT';
export const GET_ADMIN_PAYMENT='GET_ADMIN_PAYMENT';
export const SUBMIT_REPORT='SUBMIT_REPORT';
export const DELETE_REPORT='DELETE_REPORT';
export const GET_USER_COMMENT='GET_USER_COMMENT';
export const GET_ADMIN_USER_BY_ID='GET_ADMIN_USER_BY_ID';

export const GET_FAILED_PAYMENT='GET_FAILED_PAYMENT';