import * as actionTypes from "../../constants/actionTypes";

const initialState = {
  users: [],
  userlogin: null,
  profileImage:null,
  isAuthenticated: false,
  state: "idle",
  error: null,
  success: null,
};

// Retrieve the user profile from localStorage
const storedProfile = localStorage.getItem("profile");
const initialProfile = storedProfile ? JSON.parse(storedProfile) : null;

const addUserReducer = (
  state = { ...initialState, userlogin: initialProfile },
  action
) => {
  switch (action.type) {
    case actionTypes.ADD_USER:
      return {
        ...state,
        users: action.payload.users,
      };
    case actionTypes.VERIFY_OTP:
      localStorage.setItem("profile", JSON.stringify({ ...action?.payload }));
      return {
        ...state,
        isAuthenticated: true,
        userlogin: action.payload,
      };
    case actionTypes.LOGIN_USER:
      return {
        ...state,
        userlogin: action.payload,
        isAuthenticated: true,
      };

    case actionTypes.GET_USER:
      return {
        ...state,
        users: action.payload,
      };
    case actionTypes.UPDATE_USER:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case actionTypes.PROFILE_IMAGE:
      return {
        ...state,
          profileImage: action.payload.profileImage,
      };
    case actionTypes.LOGOUT_USER:
      localStorage.removeItem("profile");
      return {
        ...state,
        userlogin: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default addUserReducer;
